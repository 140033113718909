"use client";

import "@ashgw/css/global";
import type { GlobalErrorProperties } from "@ashgw/components";
import { ErrorBoundary, Footer } from "@ashgw/components";
import { fonts } from "@ashgw/ui";
import { NavBar } from "./components/nav";
export default function GlobalError({
  ...props
}: GlobalErrorProperties) {
  return <html lang="en" data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body className={fonts.atkinsonHyperlegible.className}>
        <NavBar data-sentry-element="NavBar" data-sentry-source-file="global-error.tsx" />
        <main className="flex h-screen items-start justify-center pt-20">
          <ErrorBoundary {...props} data-sentry-element="ErrorBoundary" data-sentry-source-file="global-error.tsx" />
        </main>
        <Footer data-sentry-element="Footer" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}